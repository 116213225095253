


























import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Scaffold },
  async mounted() {
    this.$store.dispatch('toggleLoading', true);
    await this.$store.dispatch(
      'tour/bindCurrentTour',
      this.$route.params.tourId
    );
    this.$store.dispatch('toggleLoading', false);
  },
  computed: {
    ...mapGetters({
      tour: 'tour/getCurrentTour',
    }),
  },
  methods: {
    shareTourURL(): void {
      if (this.tour) {
        const _navigator: any = navigator;
        _navigator.share({
          title: this.tour.title,
          text:
            'Ich bin gerade auf ridee dieser Tour beigetreten und dachte, das wäre vielleicht auch etwas für dich. ',
          url: 'https://app.ridee.cc/mobile/tours/' + this.tour.id,
        });
      }
    },
  },
});
